<template>
	<div class="order">
		<!-- 导航 -->
		<van-tabs v-model:active="state.type" :before-change="tabClick" sticky class="order-tabs">
			<van-tab title="全部订单" name="0"/>
			<van-tab title="待发货" name="1"/>
			<!-- <van-tab title="待核销" name="2"/> -->
			<van-tab title="待收货" name="3"/>
			<van-tab title="已完成" name="4"/>
		</van-tabs>
		<div style="padding-top: 2.6rem;">
			<van-list class="order-list" v-model:loading="state.loading" :finished="state.finished"
				v-model:error="state.error" finished-text="没有更多了" error-text="请求失败，点击重新加载" @load="load">
				<van-cell class="order-item" v-for="(dataRow, index) in dataRows" :key="index">
					<div class="order-item-num" @click="toDetail(dataRow)">订单号：{{ dataRow.order_num }}
						<span v-if="dataRow.take_type === 0 && dataRow.pay_status === 1 && dataRow.take_status === 0"
							style="float: right;">待发货</span>
						<span
							v-else-if="dataRow.take_type === 1 && dataRow.take_status === 0 && dataRow.pay_status === 1"
							style="float: right;">待核销</span>
						<span v-else-if="dataRow.pay_status === 1 && dataRow.take_status === 1 "
							style="float: right;">待收货</span>
						<span v-else-if="dataRow.pay_status === 1 && dataRow.take_status === 2 "
							style="float: right;">已完成</span>
						<!-- <span v-else-if="dataRow.pay_status === 1 && dataRow.take_status === 5"
							style="float: right;">已完成</span> -->
						<span v-else style="float: right;">未知状态</span>
					</div>
					<van-divider />
					<van-card class="order-card" v-for="(product, i) in dataRow.products" :key="i" :num="product.count"
						:price="product.price" :desc="product.attr" :title="product.name"
						:thumb="imageUrl+product.image" />
					<van-divider />
					<div class="order-total" v-if="dataRow.type === 1"><span
							class="order-it em-count">预约到店时间：{{ dataRow.appointment_time }}
							{{ dataRow.time_quantum }}</span></div>
					<div class="order-total"><span class="order-it em-count">下单时间：{{ dataRow.create_time }}</span><span
							class="order-item-pay">实际支付：<span style="color: #f2270c;">￥</span> <span
								class="order-item-price">{{ dataRow.money }}</span></span></div>
					<div class="order-item-act">
						<van-button
							v-if="dataRow.take_type === 0 && dataRow.pay_status === 1 && dataRow.take_status === 0"
							type="primary" size="mini" style="float: right;" @click="eventHandle('sender',dataRow.id)">
							发货</van-button>
						<van-button
							v-else-if="dataRow.take_type === 1 && dataRow.take_status === 0 && dataRow.pay_status === 1"
							size="mini" style="float: right;" class="van-button-verification"
							@click="eventHandle('verification',dataRow.id)">核销</van-button>
						<van-button
							v-if="dataRow.pay_status === 1 && dataRow.take_type === 1 && dataRow.take_status === 1"
							size="mini">查看物流</van-button>
					</div>
				</van-cell>
			<!-- <van-empty description="暂无订单" v-if="orderStatus"/> -->
			</van-list>
		
		</div>
		<van-popup v-model:show="info">
			<van-form class="pass-form">
				<van-field label="快递类型" label-width="3rem" size="large" clearable v-model="formdatas.express_type"
					name="express_type" placeholder="请输入快递类型(圆通,中通等)"
					:rules="[{ required:true, message: '请输入快递类型' }]" />
				<van-field label="快递单号" label-width="3rem" size="large" clearable v-model="formdatas.express_num"
					name="express_num" placeholder="请输入快递单号" :rules="[{ required:true, message: '请输入快递单号' }]" />
				<button class="btns" @click="fahuo">确定</button>
			</van-form>
		</van-popup>
	</div>
</template>
<script>
	import {
		orderLst,
		orderDeliver,
		orderVerification
	} from "@/api/order"
	import
	http
	from '../../utils/config.js'
	import {
		Toast,
		Dialog
	} from "vant";
	export default {
		name: "OrderList",
		data() {
			return {
				dataRows: [],
				imageUrl: http.imageUrl,
				state: {
					page: 1,
					limit: 10,
					type: 0,
					loading: true,
					finished: false,
				},
				info: false,
				formdatas: {
					order_id: '',
					express_type: '',
					express_num: ''
				},
				orderStatus:false
			}
		},
		mounted() {
			this.state.type = parseInt(this.$route.query.type)
		},
		methods: {
			load() {
				this.orderStatus = false
				console.log(this.state)
				orderLst(this.state).then((res) => {
					console.log(res)
					if (res.data === undefined) {
						this.state.loading = false
						this.state.finished = true;
						this.orderStatus = true
						return false
					}
					this.dataRows = this.dataRows.concat(res.data)
					console.log(this.dataRows)
					this.state.loading = false
					this.state.page++
					if (res.data.length < this.state.limit) this.state.finished = true;
				})
			},
			tabClick(index) {
				if (this.state.type === index) return false
				this.state = {
					page: 1,
					limit: 10,
					type: index,
					loading: true,
					finished: false,
				}
				this.dataRows = []
				this.load()
			},
			toDetail(row) {
				this.$router.push({
					path: '/order/detail',
					query: {
						order_id: row.id
					}
				})
			},
			eventHandle(event, id) {
				this.formdatas.order_id = id

				switch (event) {
					case 'sender':
						console.log('发货')
						this.info = true
						this.fahuo()
						break
					case 'verification':
						console.log('核销')
						console.log(this.formdatas.order_id)
						let params = {
							order_id: this.formdatas.order_id
						}
						orderVerification(params).then((res) => {
							console.log(res)
							Toast({
								message: res.msg,
							});
							this.dataRows = []
							this.load()
						})
						break
				}
			},
			fahuo() {
				let params = {
					order_id: this.formdatas.order_id,
					sender_type: 1,
					express_type: this.formdatas.express_type,
					express_num: this.formdatas.express_num
				}
				if (!this.formdatas.express_num) {
					return
				}
				if (!this.formdatas.express_type) {
					return
				}
				orderDeliver(params).then((res) => {
					console.log(res)
					Toast({
						message: res.msg,
					});
					this.dataRows = []
					this.state.page = 1
					this.load()
					this.info = false
				})
			}
		}
	}
</script>

<style lang="less" scoped>
	@import "~@/assets/style/preset.less";

	.order {
		min-height: calc(100vh);

		.order-list {
			margin: 10px 10px 0 10px;
		}

		.order-card {
			background: white;
		}

		.order-item {
			margin-top: 10px;
			border-radius: 10px;
			background: white;
		}

		.order-item-num {
			padding-top: 5px;
		}

		.order-item-num {
			font-size: 12px;
		}

		.order-item-act {
			margin-top: 10px;
			padding-bottom: 10px;
			text-align: right;
		}

		.order-total {
			font-size: 12px;
			height: 20px;
		}

		.order-item-count {
			float: left;
			color: #666666;
		}

		.order-item-pay {
			float: right;
		}

		.order-item-price {
			color: @price-color;
			font-size: 16px;
		}

		.order-tabs {
			position: fixed;
			top: 0;
			z-index: 2;
			width: 100%;
		}

		.van-button-verification {
			color: #ffffff;
			background: #1989fa;
			border: 1px solid #1989fa;
		}

		.pass-form {
			width: 19rem;
			padding: 2rem 1rem;

			.btns {
				width: 100%;
				height: 40px;
				background-color: #ee0a24;
				border: solid 1px #ee0a24;
				border-radius: 20px;
				color: #FFFFFF;
			}
		}
	}
</style>
